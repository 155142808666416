window.lui_map_responses = {};

application.register("comp_stores", class extends Stimulus.Controller {

    get target() {
        return {
            map: this.targets.find("map"),
            infoWindow: this.targets.find("infoWindow")
        }
    }

    connect() {
        let self = this, element = self.element, options = JSON.parse(element.dataset.options),
            markersUrl = element.dataset.markers;

        self._mouseOver = false;

        inView(element, () => {
            importScript([cdnjs.googlemaps.replace("{apikey}", options["apikey"]), cdnjs.googlemaps_cluster], () => {
                let coords = options["center"].split(/,\s/);
                let bounds;
                let markers_temp = [];
                let info_template = self.target.infoWindow.innerHTML;

                let map = new google.maps.Map(self.target.map, {
                    center: {lat: parseFloat(coords[0]), lng: parseFloat(coords[1])},
                    zoom: 12,
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    styles: [
                        {
                            "featureType": "all",
                            "elementType": "labels.text",
                            "stylers": [
                                {
                                    "color": "#878787"
                                }
                            ]
                        },
                        {
                            "featureType": "all",
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "color": "#f9f5ed"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#c9c9c9"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "color": "#aee0f4"
                                }
                            ]
                        }
                    ]
                });

                let infoWindow = new google.maps.InfoWindow({
                    content: ""
                });
                let icon = {
                    url: '/assets/images/marker.svg',
                    scaledSize: new google.maps.Size(56, 85),
                    anchor: new google.maps.Point(28, 85)
                };

                function getMarkers(url, callback) {
                    fetch(url, {headers: {'X-Requested-With': 'XMLHttpRequest'}}).then((response) => {
                        return response.json();
                    }).then((payload) => {
                        window.lui_map_responses[url] = payload;
                        callback(payload);
                    });
                }

                function generateMarker(item) {
                    let title = item["title"];
                    let point = new google.maps.LatLng(parseFloat(item["latitude"]), parseFloat(item["longitude"]));
                    let content = info_template
                        .replaceAll("*title*", title)
                        .replaceAll("*url*", item["url"])
                        .replaceAll("*address*", item["address"]);

                    let marker = new google.maps.Marker({
                        title: title,
                        map: map,
                        position: point,
                        icon: icon,
                    });
                    bounds.extend(point);
                    markers_temp.push(marker);

                    function showInfo(marker) {
                        infoWindow.setContent(content);
                        infoWindow.open(map, marker);
                    }

                    marker.addListener('click', () => {
                        showInfo(marker);
                    });
                }

                map.addListener("click", () => {
                    infoWindow.close();
                })

                getMarkers(markersUrl, (payload) => {
                    bounds = new google.maps.LatLngBounds();

                    for (let item of payload) {
                        generateMarker(item);
                    }
                    let markerCluster = new MarkerClusterer(map, markers_temp, {
                        styles: [{
                            url: "/assets/images/marker-cluster.svg",
                            width: 56,
                            height: 56,
                            fontFamily: "Cabrito,sans-serif",
                            fontWeight: "600",
                            textSize: 16,
                            textColor: "#000000",
                            textLineHeight: 56
                        }]
                    });

                    if (payload.length > 0) {
                        map.fitBounds(bounds);
                    }
                });
            });
        })

        self.target.map.addEventListener('mouseenter', (e) => {
            self._mouseOver = true
        })

        self.target.map.addEventListener('mouseleave', (e) => {
            self._mouseOver = false
        })
    }

    handleKeydown(e) {
        if((e.which === 17 || e.which === 18 || e.metaKey) && this._mouseOver) {
            locomotive.stop()
        }
    }

    handleKeyup(e) {
        if((e.which === 17 || e.which === 18 || e.metaKey) && this._mouseOver) {
            locomotive.start()
        }
    }
});
