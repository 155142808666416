application.register("comp_visual_main", class extends Stimulus.Controller {

    scrollNext() {
        let nextElm = this.element.nextElementSibling,
            offset = 100;

        if (typeof locomotive !== "undefined") {
            locomotive.scrollTo(nextElm, -offset);
        }
        else {
            document.documentElement.scroll({top: nextElm.offsetTop - offset, behavior: "smooth"});
        }
    }
});