application.register("comp_products_list", class extends Stimulus.Controller {

    get target() {
        return {
            nav: this.targets.find("nav"),
            head: this.targets.find("head"),
            body: this.targets.find("body")
        }
    }

    connect() {
        this._ticking = false;
        this._scrollOffset = 350;
        this._offsets = {};

        cssLoaded(() => {
            if(this.target.nav.querySelector(".state--active") !== null) {
                this.target.nav.querySelector(".row").scrollLeft = this.target.nav.querySelector(".state--active").offsetLeft - 20;
            }

            if (typeof locomotive !== "undefined" && !locomotive.isMobile) {
                this._scrollOffset = this.target.head.offsetHeight - document.querySelector("#layout_header").offsetHeight;
                this.target.nav.setAttribute("data-scroll-offset", this._scrollOffset);
                locomotive.update();
            }
        });
    }

    updateNav(e) {
        let elm = e.currentTarget;
        if(this.target.nav.querySelector(".state--active") !== null) {
            this.target.nav.querySelector(".state--active").classList.remove("state--active");
        }
        elm.classList.add("state--active");
        this.target.nav.querySelector(".row").scrollLeft = elm.offsetLeft - 20;
    }
});
