application.register("layout_header", class extends Stimulus.Controller {
    connect() {
        if (document.querySelector("#layout_nav") === null) {
            this.element.insertAdjacentHTML("afterend", `<div id="layout_nav" class="lib--drawer" data-target="lib-drawer.nav" data-action="scroll->lib-drawer#scroll"><div class="wrp_nav"><div class="wrp_nav_head"></div><div class="wrp_nav_body"></div></div></div>`);

            application["getControllerForElementAndIdentifier"](document.body, "lib-drawer").init();

            let layout_nav = document.querySelector("#layout_nav"),
                logo = this.element.querySelector(".elm_header_logo").outerHTML,
                nav = this.element.querySelector(".elm_header_nav").outerHTML,
                login = this.element.querySelector(".elm_header_login").outerHTML;

            layout_nav.querySelector(".wrp_nav_head").insertAdjacentHTML("beforeend", logo);
            layout_nav.querySelector(".wrp_nav_body").insertAdjacentHTML("beforeend", login);
            layout_nav.querySelector(".wrp_nav_body").insertAdjacentHTML("beforeend", nav);
        }

        let self = this;

        self._currentScrollY = 0;
        self._latestKnownScrollY = 0;
        self._ticking = false;
        self._offset = 5;

        bodyLoaded(() => {
            setTimeout(() => {
                if (typeof locomotive !== "undefined" && !locomotive.isMobile) {
                    locomotive.on("scroll", (e) => {
                        self.onScroll(e.delta.y);
                    });
                } else {
                    window.addEventListener('scroll', ()=>{
                        self.onScroll(window.scrollY);
                    }, false);
                }
            }, 100);
        })
    }

    disconnect() {
        let self = this;

        window.removeEventListener('scroll', ()=>{
            self.onScroll(window.scrollY);
        }, false);
    }

    onScroll(currentScrollY) {
        let self = this, element = self.element;

        if (!self._ticking) {
            requestAnimationFrame(() => {
                if (currentScrollY >= self._offset) {
                    element.classList.add("header--not-top");
                    if (currentScrollY > self._latestKnownScrollY) {
                        element.classList.remove("header--pinned");
                        element.classList.add("header--unpinned");
                    } else {
                        element.classList.remove("header--unpinned");
                        element.classList.add("header--pinned");
                    }
                } else {
                    element.classList.remove("header--not-top", "header--pinned", "header--unpinned");
                }
                self._latestKnownScrollY = currentScrollY;
            });

            self._ticking = false;
        } else {
            self._ticking = true;
        }
    }
});