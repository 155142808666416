application.register("lib-parallax", class extends Stimulus.Controller {
    connect() {
        inView(this.element,()=>{
            if (importScript.used.indexOf(cdnjs.parallax) === -1) {
                setTimeout(()=>{
                    this.loadLib();
                },3000);
            }
            else {
                this.loadLib();
            }
        })
    }

    loadLib() {
        importScript(cdnjs.parallax,()=>{
            let params = {
                selector: "[data-depth]"
            };
            if (window.outerWidth < 600) {
                params = {
                    scalarX: 24,
                    scalarY: 24,
                    selector: "[data-depth]"
                };
            }
            let parallax = new Parallax(this.element,params);
        });
    }
});